import { sendEmailToGeneralContractorContactAPI } from "../requests/projects";

export const api = {
  authentication: {
    register: "register",
    login: "login",
    fetchUser: "users/:userId",
    passwordReset: "password_reset/",
    passwordResetConfirm: "password_reset/confirm/",
  },
  PDF: {
    TotalStatements: "statements/get_total_statements",
    capabilityStatement: "statements/capability_statement",
    capabilityStatementCreate: "statements/capability_statement_create",
    DeleteStatement: "statements/delete_statement",
  },
  proposals: {
    saveProposal: "proposals",
    getAllProposal: "proposals",
    getProposal: "proposals/:proposalId",
    deleteProposal: "proposals/:proposalId",
    updateProposal: "proposals/:proposalId",
    createSubscription: "proposals/subscription",
  },
  site_visits: {
    getAllSiteVisit: "site_visits",
    saveSiteVisit: "site_visits",
    getSiteVisit: "site_visits/:siteVisitId",
    getSiteVisitByProject: "site_visits/:siteVisitProject",
    updateSiteVisit: "site_visits/:siteVisitId",
    deleteSiteVisit: "site_visits/:siteVisitId",
    downloadSiteVisit: "site_visits/download/:siteVisitId"
  },
  projectTypes: {
    getAllProjectTypes: "project-types",
    getAllUserProjectTypes: "project-types/user",
    getProjectTypes: "project-types/:projectTypeId",
  },
  proposalTemplates: {
    getAllTemplates: "paint_proposals/templates",
    saveProposal: "paint_proposals/proposal",
    getAllProposals: "paint_proposals/proposal",
    getProposal: "paint_proposals/proposal/:proposalId",
    updateProposal: "paint_proposals/proposal/:proposalId",
    deleteProposal: "paint_proposals/proposal/:proposalId"
  },
  users: {
    updateUser: "users/:userId",
    editUser: "users/profile",
    freeMode: "users/free_mode_action",
    lifetimeOffer: "users/lifetime_offer",
    freeModeSqFt: "users/free_sqft_changes",
    manageUserBilling: "users/create-customer-portal-session",
    dashboard: "users/page-dashboard",
    saveDashboard: "users/save-dashboard"

  },
  projects: {
    all: "projects",
    list: "projects/list",
    byLocations: "projects?coordinates=:locations",
    singleProject: "projects/:projectId",
    archiveProject: "projects/:projectId/archive",
    unArchiveProject: "projects/:projectId/unarchive",
    likeProject: "projects/:projectId/favorite",
    unLikeProject: "projects/:projectId/unfavorite",
    saveSearchProject: "projects/save-search",
    deleteSearchProject: "projects/delete-search/:search-id?userId=:user-id",
    saveComment: "project/:projectId/comments",
    getComment: "project/:projectId/comments",
    deleteComment: "project/:projectId/comments/:commentId",
    editComment: "project/:projectId/comments/:commentId",
    saveStatus: "user-projects-status",
    saveSequnce: "projects/save-sequence",
    addSiteRoute: "projects/:projectId/site_route",
    removeSiteRoute: "projects/:projectId/remove_site_route",
    updateNotes: "projects/site_route_notes/0",
    updateLastContact: "projects/contact_last_date/0",
    projectNotes: "projects/project_notes/:projectId",
    projectVisit: "projects/project_visit/:projectId",
    addActivity: "projects/add_activity/0",
    deleteActivity: "projects/delete_activity/:activityId",
  },
  callLogs: {
    saveCallLog: "project/:projectId/call-logs",
    allCallLogs: "project/:projectId/call-logs",
  },
  contact: {
    getEmailTemplate: "email-templates/:templateId",
    sendEmailToContactAPI: "project/:projectId/emails",
    sendEmailToGeneralContractorContact: "contacts/:contactId/emails",
    getEmailLogs: "project/:projectId/emails",
    getContactRoles: "project/:projectId/contact-roles",
    deleteUserContact: "contacts/:contactId/delete",
    deleteUserCompany: "contacts/:contactId/delete_company",
  },
  replies: {
    saveReply: "comments/:commentId/replies",
    editReply: "comments/:commentId/replies/:replyId",
    deleteReply: "comments/:commentId/replies/:replyId",
  },
  notification: {
    getNotifications: "project-notifications",
  },
  hotScopes: {
    getHotScopes: "projects/hotscopes",
  },
  regions: {
    getRegions: "gc_qualify/regions",
  },

  mortgageCalculator: {
    getBidAmountPricing:
      "mortgage_calculator/bidamount_pricing/:projectType?phase=:phase",
    getStateLaborPricing: "mortgage_calculator/state_labor_pricing/:state",
    getStateLaborPricingList: "mortgage_calculator/state_labor_pricing",
    getProjectTypePricingList: "mortgage_calculator/bidamount_pricing",
    saveEstimates: "mortgage_calculator/cleanup_estimates",
    getAllEstimates: "mortgage_calculator/cleanup_estimates",
    getEstimates: "mortgage_calculator/cleanup_estimates/:id",
    deleteEstimates: "mortgage_calculator/cleanup_estimates/:id",
    updateEstimates: "mortgage_calculator/cleanup_estimates/:id",
    calculationInfo: "mortgage_calculator/calculation_info",
  },
  gcQualify: {
    regions: "gc_qualify/regions",
    companies: "projects/companies?region=:region",
    updatePlanRoom: "gc_qualify/plan_rooms",
    updatePreQualify: "gc_qualify/pre_qualify_create",
  },
};

export const TOTAL_FREE_MODE_COUNT = 10;
