import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addContactActivity } from "../../store/actions/projects/projects.action";
import { error } from "jquery";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;

const AddActivityModal = (props) => {
  const dispatch = useDispatch();

  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const localTime = new Date(now.getTime() - offset);
    return localTime.toISOString().slice(0, 16);
  };

  const [formData, setFormData] = useState({
    date: getCurrentDateTime(),
    notes: "",
    subject: "",
    contact_id: props.contact.contact_id,
    email: props.email,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) newErrors.date = "Date is required";
    if (!formData.notes) newErrors.notes = "Notes are required";
    return newErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    dispatch(
      addContactActivity(
        new Date(formData.date).toISOString().slice(0, 16),
        formData.contact_id,
        `${formData.subject} - ${formData.notes}`,
        formData.subject
      )
    );

    props.onHide();

    setFormData({
      date: "",
      notes: "",
      subject: "",
      contact_id: props.contact.contact_id,
      email: props.email,
    });
  };

  return (
    <StyledModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Activity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="activity-date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  isInvalid={!!errors.date}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="activity-subject">
                <Form.Label>Subject</Form.Label>
                <Form.Select
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  isInvalid={!!errors.subject}
                >
                  <option value=" ">--</option>
                  <option value="Email">Email</option>
                  <option value="Proposal submitted">Proposal submitted</option>
                  <option value="Proposal follow">Proposal follow</option>
                  <option value="Call No Response">Call No Response</option>
                  <option value="Voicemail">Voicemail</option>
                  <option value="Call Conversation">Call Conversation</option>
                  <option value="SMS">SMS</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.subject}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="activity-notes" className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              isInvalid={!!errors.notes}
            />
            <Form.Control.Feedback type="invalid">
              {errors.notes}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

AddActivityModal.defaultProps = {
  closeButton: true,
};

export default AddActivityModal;
