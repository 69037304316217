import {
  REORDER_DASHBOARDS,
  FAILURE_DASHBOARDS,
  REQUEST_DASHBOARDS,
  SUCCESS_DASHBOARDS,
  TOGGLE_GRAPH_VISIBILITY,
  UPDATE_USER_DASHBOARD,
} from "../../constants/dashboardConstants";

const initialState = {
  dashboards: [
    {
      id: 1,
      title: "Project Visits",
      prop: "project_visits",
      hidden: false,
      index: 0,
    },
    { id: 2, title: "Estimates", prop: "estimates", hidden: false, index: 1 },
    { id: 3, title: "Proposals", prop: "proposals", hidden: false, index: 2 },
    { id: 4, title: "Activities", prop: "activities", hidden: false, index: 3 },
    { id: 5, title: "Deal Value Tracker", prop: "deals", hidden: false, index: 4 },
  ],
  data: {
    project_visits: [],
    estimates: [],
    proposals: [],
    activities: [],
    deals: []
  },
  info: {
    project_visits: "Number of times a user visits or interacts with a project in the system",
    estimates: "Number of pricing estimates created and saved",
    proposals: "Number of project proposals that have been drafted saved",
    activities: "A count of all actions logged related to a project, such as calls, emails, sms, proposals submitted, or any other engagement.",
    deals: "Track the total deal value of the projects you worked on. This will give you a clear understanding  of your pipeline’s overall value and help you assess its potential for future opportunities."
  },
  loading: false,
  error: "",
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case REORDER_DASHBOARDS:
      const reorderedDashboards = [...state.dashboards];
      const [removed] = reorderedDashboards.splice(
        action.payload.source.index,
        1
      );
      reorderedDashboards.splice(action.payload.destination.index, 0, removed);

      return {
        ...state,
        dashboards: reorderedDashboards.map((dashboard, index) => ({
          ...dashboard,
          index,
        })),
      };

    case TOGGLE_GRAPH_VISIBILITY:
      const updatedDashboards = state.dashboards.map((dashboard) =>
        dashboard.id === action.payload ? { ...dashboard, hidden: !dashboard.hidden } : dashboard
      );
      return {
        ...state,
        dashboards: updatedDashboards,
      };

    case UPDATE_USER_DASHBOARD:
      const updatedDashboardsForUser = state.dashboards.map((dashboard) => {
        const updatedDashboard = action.dashboard.find((d) => d.id === dashboard.id);
        if (updatedDashboard) {
          return {
            ...dashboard,
            index: updatedDashboard.index,
            hidden: updatedDashboard.hidden,
          };
        }
        return dashboard;
      });
      return {
        ...state,
        dashboards: updatedDashboardsForUser,
      };

    case REQUEST_DASHBOARDS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_DASHBOARDS:
      return {
        ...state,
        data: action.response,
        loading: false,
        error: "",
      };
    case FAILURE_DASHBOARDS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
