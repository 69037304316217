export const REORDER_DASHBOARDS = "REORDER_DASHBOARDS";

export const TOGGLE_GRAPH_VISIBILITY = "TOGGLE_GRAPH_VISIBILITY";

export const UPDATE_USER_DASHBOARD = "UPDATE_USER_DASHBOARD";

export const REQUEST_DASHBOARDS = "REQUEST_DASHBOARDS";
export const SUCCESS_DASHBOARDS = "SUCCESS_DASHBOARDS";
export const FAILURE_DASHBOARDS = "FAILURE_DASHBOARDS";

export const REQUEST_SAVE_DASHBOARDS = "REQUEST_SAVE_DASHBOARDS";
export const SUCCESS_SAVE_DASHBOARDS = "SUCCESS_SAVE_DASHBOARDS";
export const FAILURE_SAVE_DASHBOARDS = "FAILURE_SAVE_DASHBOARDS";
