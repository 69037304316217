import { delete$, get$, post$, put$ } from "./default";
import { api } from "../constants/api";

export const getDashboardAPI = (start_date, end_date, user) => {
  const url = api.users.dashboard;
  const apiURL = `${url}?start=${start_date}&end=${end_date}&user=${user}`;
  return get$(decodeURI(apiURL));
};

export const saveDashboardAPI = (data) => {
  const url = api.users.saveDashboard;
  return post$(url, data, {}, true);
};
