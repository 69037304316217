import React, { useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Nav,
  Tooltip,
  OverlayTrigger,
  Accordion,
} from "react-bootstrap";
import styled from "styled-components";
import CalculatorBidAmount from "../../components/calculator/CalculatorBidAmount";
import CalculatorCostProfit from "../../components/calculator/CalculatorCostProfit";
import CalculatorBidAmountResult from "../../components/calculator/CalculatorBidAmountResult";
import CalculatorCostProfitResult from "../../components/calculator/CalculatorCostProfitResult";
import { useDispatch, useSelector } from "react-redux";
import RecentEstimates from "../../components/RecentEstimates";
import {
  fetchEstimateFromStorage,
  fetchStateFromStorage,
  getCleanUpEstimatesDetail,
  saveCleanUpEstimate,
  updateCleanUpEstimate,
} from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  BID_AMOUNT_RESET_VALUES,
  BID_AMOUNT_SET_VALUES,
} from "../../store/constants/mortgageConstant";
import { TOTAL_FREE_MODE_COUNT } from "../../utils/constants/api";
import { USER_SET_FREE_MODE_SUCCESS } from "../../store/constants/userConstants";
import {
  isSubscriptionActive,
  toastError,
  userSubscribedPlans,
} from "../../utils/helpers/helper";
import Scrollspy from "react-scrollspy";
import LoginModal from "../../components/LoginModal";
import SubscriptionModal from "../../components/subscriptionModal";
import { StyledAccordionHeader } from "../../components/calculator/CalculatorForm.styled";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";

const StyledSectionTwo = styled.div`
  background-color: rgb(246 248 250);
  height: fit-content;
  top: 0;
  right: 0;
  position: sticky;
  @media (min-width: 1200px) {
    // position: fixed;
    // right: 48px;
  }
`;
const StyledRow = styled(Row)`
  padding-top: 4rem;
  position: relative;
`;

const StyledPreviewText = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 1.8rem;
`;

const StyledPreviewTextH6 = styled.h6`
  color: #000;
  font-weight: 700;
  font-size: 1rem;
`;

const StyledTabs = styled(Tabs)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .nav-item button#noanim-tab-example-tab-new {
    color: #fff;
    background-color: #276fb4;
  }
`;

const CleanupCalculator = () => {
  const [activeTab, setActiveTab] = useState("bidAmount");
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [estimateTabName, setEstimateTabName] = useState("New Estimate");
  const [formTouched, setFormTouched] = useState(false);
  const { search } = window.location;
  const show_tab = new URLSearchParams(search).get("show_tab");
  const opportunity_id = new URLSearchParams(search).get("opportunity_id");
  const { user } = useSelector((state) => state.auth);
  const { user: profile } = useSelector((state) => state.userDetails);
  const navigate = useNavigate();
  let params = useParams();

  const { id } = params;
  const showFormTab = (e) => {
    if (e === "new") {
      setEstimateTabName("New Estimate");
      dispatch({ type: BID_AMOUNT_RESET_VALUES });
      navigate("/cleanup_calculator");
      setActiveTab("bidAmount");
      return;
    } else if (e === "faq") {
      window.location.href =
        "https://learn.ganarpro.com/business-suite-intro-cleanup-calculator-overview/";
      return;
    }

    setActiveTab(e);
  };
  const [arrowAmount, setArrowAmount] = useState(0);
  const [bidAmountState, setBidAmountState] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [topPosition, setTopPosition] = useState("20%");
  const values = useSelector((state) => state.bidAmountValues);
  const costValues = useSelector((state) => state.costProfitValues);
  const { loading } = useSelector((state) => state.cleanUpEstimateCreate);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { estimate, loading: estimateLoader } = useSelector(
    (state) => state.cleanUpEstimateDetail
  );
  const { loading: estimateListLoader } = useSelector(
    (state) => state.cleanUpEstimateList
  );
  const [isEdit, setIsEdit] = useState(false);
  const [download, setDownload] = useState(false);
  const [editPricePerday, setEditPricePerDay] = useState(false);
  const [pricePerDay, setPricePerDay] = useState(0);

  const {
    percentage,
    one_day_work,
    loading: stateLaborLoader,
  } = useSelector((state) => state.stateLaborPricing);
  const { amount, loading: bidAmountLaborLoader } = useSelector(
    (state) => state.bidAmountPricing
  );
  const [projectNameErrorMessage, setProjectNameErrorMessage] = useState(false);

  const [bidAmountManualChange, setBidAmountManualChange] = useState(0);
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const dispatch = useDispatch();

  const bidAmountRef = useRef(null);

  const manualBidAmount = (value = 0) => {
    if (parseFloat(value) > 0) {
      setBidAmountState(value);
      setArrowAmount(value);
      return;
    }
  };
  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = id ? values?.project_name : "Calculator";
    const type = "Calculator";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, values?.project_name]);

  const [selectedKey, setSelectedKey] = useState(false);
  const selectedName = selectedKey
    ? "Show Your Cost & Profit Prediction"
    : "Hide Your Cost & Profit Prediction";
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Calculator");

  useEffect(() => {
    const nav = document.querySelector("ul.nav.nav-tabs");
    const header = document.querySelector("header.justify-content-between");
    const mainRow = document.querySelector("#mainRow");
    const pageTitle = document.querySelector("#pageTitleCal");

    const height =
      parseInt(nav.offsetHeight) +
      parseInt(header.offsetHeight) +
      parseInt(pageTitle.offsetHeight);

    setTopPosition(`${height}px`);
  }, [costValues]);

  const calculateBidAmount = () => {
    let { state, phase: phaseKey, project_type, square_foot } = values;
    const storedPrice = localStorage.getItem(
      `${state}_${phaseKey}_${project_type}`
    );
    let sqtFootPrice = amount;

    let sign = percentage < 0 ? -1 : 1;

    if (values?.state !== "") {
      sqtFootPrice = Math.abs(percentage) * amount + sign * amount;
    }

    if (storedPrice !== null) sqtFootPrice = storedPrice;

    let percnt = 0;
    sqtFootPrice = parseFloat(sqtFootPrice);

    if (values.union_checked) percnt += parseFloat(values?.union_percentage);
    if (values.davis_checked) percnt += parseFloat(values?.davis_percentage);

    sqtFootPrice = sqtFootPrice + (sqtFootPrice * percnt) / 100;

    const pricePer1bedResult =
      values?.price_per1_bed * parseInt(values?.no_of_unit1_bed);
    const pricePer2bedResult =
      values?.price_per2_bed * parseInt(values?.no_of_unit2_bed);
    const pricePer3bedResult =
      values?.price_per3_bed * parseInt(values?.no_of_unit3_bed);
    const pricePerStudioResult =
      values?.price_per_studio * values?.no_of_unit_studio;

    const totalPricePerRoom =
      pricePer1bedResult +
      pricePer2bedResult +
      pricePer3bedResult +
      pricePerStudioResult;

    let bidAmount = bidAmountState;

    const calculatePricePerUnit = () => {
      let pricePerUnit = parseFloat(
        (
          Math.abs(percentage) * values.totalSumOfPhases +
          sign * values.totalSumOfPhases
        ).toFixed(0)
      );

      pricePerUnit = pricePerUnit < 0 ? pricePerUnit * -1 : pricePerUnit;
      pricePerUnit = parseFloat(pricePerUnit);
      pricePerUnit = pricePerUnit + (pricePerUnit * percnt) / 100;
      return pricePerUnit.toLocaleString();
    };
    if (id && !formTouched) {
      bidAmount = estimate?.bid_amount || 0;
    } else if (values?.use_unit_pricing) {
      bidAmount = totalPricePerRoom;
    } else if (values?.sqft_and_units) {
      if (values?.use_price_unit_field) {
        bidAmount = Number(values?.living_unit) * values?.pricePerUnit;
      } else {
        bidAmount = Number(values?.living_unit) * calculatePricePerUnit();
      }
      bidAmount += Math.abs(
        Math.abs(sqtFootPrice).toFixed(2) * values?.square_foot
      );
    } else if (values?.use_living_unit_pricing) {
      if (values?.use_price_unit_field) {
        bidAmount = Number(values?.living_unit) * values?.pricePerUnit;
      } else {
        bidAmount = Number(values?.living_unit) * calculatePricePerUnit();
      }
    } else if (costValues?.use_number_of_days) {
      bidAmount =
        Number(costValues?.noOfDaysExpected) *
        (editPricePerday ? pricePerDay : one_day_work);
    } else {
      bidAmount = Math.abs(
        Math.abs(sqtFootPrice).toFixed(2) * values?.square_foot
      );
    }

    if (values?.emergency_pricing) {
      bidAmount = 0.1 * bidAmount + bidAmount;
    }
    if (values?.scrubbing_pricing) {
      bidAmount = 0.15 * bidAmount + bidAmount;
    }
    // if (values?.no_stories_check) {
    //   bidAmount = values?.window_panes
    //     ? values?.window_panes * values?.price_per_window + bidAmount
    //     : values?.no_of_stories_result + bidAmount;
    // }
    // if (values?.pressure_wash) {
    //   bidAmount = (values?.pressure_wash * values?.pressure_wash_price) + bidAmount;
    // }
    // if (values?.window_panes > 0) {
    //   bidAmount +=
    //     Number(values?.window_panes) * Number(values?.price_per_window);
    // }

    bidAmount = bidAmount < 0 ? bidAmount * -1 : bidAmount;
    setBidAmountState(Math.round(bidAmount));
  };

  const bidAmountHandler = (event) => {
    if (Number(event.target.value) > 900000000) {
      return;
    }
    manualBidAmount(Math.round(event.target.value));
    setBidAmountManualChange(Math.round(event.target.value));
  };

  useEffect(() => {
    calculateBidAmount();
    setEstimateTabName(values.project_name || "New Estimate");
  }, [values, costValues, amount]);

  const handleIncreaseBidAmount = () => {
    const newAmount = parseFloat(bidAmountState) + 100;
    setArrowAmount(Math.round(newAmount));
    setBidAmountState(Math.round(newAmount));
  };
  const handleDecreaseBidAmount = () => {
    const newAmount = parseFloat(bidAmountState) - 100;
    setArrowAmount(Math.round(newAmount));
    setBidAmountState(Math.round(newAmount));
  };

  const handleSquareFootPriceChange = (sqtFootPrice) => {
    const bidAmount =
      parseFloat(values?.square_foot) * parseFloat(sqtFootPrice);
    setBidAmountState(Math.round(bidAmount));
  };

  const handleSetLoginModal = (e) => {
    // e.preventDefault();
    localStorage.setItem("estimate", JSON.stringify(values));
    setModalShow(true);
    return;
  };

  const submitHandler = (e, createProposal) => {
    e.preventDefault();
    if (!hasFeatureAccess) {
      enforceAccess();
    } else {
      let new_values = values;
      if (values.project_name === "") {
        toastError("Estimate name is required");
        setProjectNameErrorMessage(true);
        bidAmountRef.current?.scrollIntoView({ behaviour: "smooth" });
        window.scrollTo(0, 0);
        return;
      }
      let bid_amount_changed = parseInt(bidAmountState);
      if (values?.no_stories_check) {
        bid_amount_changed = values?.window_panes
          ? bid_amount_changed + values?.window_panes * values?.price_per_window
          : bid_amount_changed + values?.no_of_stories_result;
      }
      if (values?.pressure_wash) {
        bid_amount_changed =
          bid_amount_changed +
          values?.pressure_wash * values?.pressure_wash_price;
      }
      dispatch(
        saveCleanUpEstimate(
          {
            ...costValues,
            ...new_values,
            no_stories: Number(values.no_stories),
            no_of_stories_result: Number(values.no_of_stories_result),
            bid_amount: bid_amount_changed.toFixed(0),
            notes: values?.notes,
            opportunity_id,
          },
          createProposal
        )
      );

      dispatch({
        type: USER_SET_FREE_MODE_SUCCESS,
        payload: free_mode_count + 1,
      });
    }
  };

  const updateHandler = (e, createProposal) => {
    e.preventDefault();
    let newValues = values;
    let bid_amount_changed = parseInt(bidAmountState);
    if (values?.no_stories_check) {
      bid_amount_changed = values?.window_panes
        ? bid_amount_changed + values?.window_panes * values?.price_per_window
        : bid_amount_changed + values?.no_of_stories_result;
    }
    if (values?.pressure_wash) {
      bid_amount_changed =
        bid_amount_changed +
        values?.pressure_wash * values?.pressure_wash_price;
    }
    if (user) {
      newValues = {
        ...costValues,
        ...values,
        no_stories: Number(values.no_stories),
        no_stories_result: Number(values.no_of_stories_result),
        bid_amount: bid_amount_changed.toFixed(0),
        dollar_contract_amount: costValues?.dollar_contract_amount,
        dollar_contract_percent: costValues?.dollar_contract_percent,
      };
    }
    dispatch(updateCleanUpEstimate(id, newValues, createProposal));
  };

  const handleSetPaymentModalShow = () => {
    setPaymentModalShow(true);
  };

  const price_id = process.env.REACT_APP_CALC_APP;

  let handleButton = submitHandler;
  let buttonText = "Save Estimate";

  if (user && estimate && id) {
    buttonText = "Update Estimate";
  } else if (!user) {
    handleButton = handleSetLoginModal;
  } else if (user && estimate && id) {
    handleButton = updateHandler;
    buttonText = "Update Estimate";
  }

  const submitAndCreate = (createProposal, originalSubmitHandler) => {
    return (event) => {
      if (
        originalSubmitHandler !== handleSetPaymentModalShow &&
        originalSubmitHandler !== handleSetLoginModal
      ) {
        originalSubmitHandler(event, createProposal);

        if (values?.davis_checked && values?.davis_percentage != 30) {
          localStorage.setItem(
            `davis_percentage_${user?.id}`,
            values?.davis_percentage
          );
        } else {
          localStorage.removeItem(`davis_percentage_${user?.id}`);
        }

        if (values?.union_checked && values?.union_percentage != 70) {
          localStorage.setItem(
            `union_percentage_${user?.id}`,
            values?.union_percentage
          );
        } else {
          localStorage.removeItem(`union_percentage_${user?.id}`);
        }
      } else {
        originalSubmitHandler();
      }
    };
  };

  const handleAccordionSelect = (e) => {
    setSelectedKey(!selectedKey);
    localStorage.setItem(`cost_profit_${user?.id}`, e === null);
    if (e === "0")
      setTimeout(() => {
        window.location.href = "#costProfit";
      }, 1000);
  };

  const estimateItem = JSON.parse(localStorage.getItem("estimate"));
  useEffect(() => {
    if (show_tab) {
      setActiveTab("recent");
    }
    setEstimateTabName(() => values?.project_name || "New Estimate");

    if (estimateItem?.project_name !== "" && user) {
      dispatch(fetchEstimateFromStorage());
    }

    // dispatch(fetchStateFromStorage());
  }, [
    dispatch,
    estimateItem?.project_name,
    free_mode_count,
    id,
    navigate,
    price_id,
    profile,
    show_tab,
    user,
    values?.project_name,
  ]);

  useEffect(() => {
    if (id && user) {
      dispatch(getCleanUpEstimatesDetail(id));
      setActiveTab("bidAmount");
    }
  }, [id, navigate, price_id, profile, user]);

  useEffect(() => {
    const davis = localStorage.getItem(`davis_percentage_${user?.id}`);
    const union = localStorage.getItem(`union_percentage_${user?.id}`);

    if (localStorage.getItem(`cost_profit_${user?.id}`) !== null) {
      setSelectedKey(
        localStorage.getItem(`cost_profit_${user?.id}`) === "true"
      );
    }
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        davis_percentage: davis || 30,
        union_percentage: union || 70,
      },
    });
  }, [user]);

  useEffect(() => {
    dispatch(fetchPageFavorite("Cleanup Calculator"));
  }, [dispatch]);

  const handlePricePerDay = (result, indicator) => {
    setEditPricePerDay(indicator);
    setPricePerDay(result);
  };
  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Cleanup Calculator" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({ page_name: "Cleanup Calculator", page_url: path })
      );
    }
  };

  const downloadTooltip = <Tooltip id="tooltip">Download as PDF</Tooltip>;
  const tooltip = (
    <Tooltip id="favorite-tooltip">
      {isFavorite ? "Unfavorite" : "Add to Favorite"}
    </Tooltip>
  );

  return (
    <div className="bg-white ms-lg-3">
      <Container>
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <StyledRow id="mainRow">
          {values?.project_name === "" && projectNameErrorMessage && (
            <Alert variant="danger">
              <Alert.Heading>Required Fields!</Alert.Heading>
              Estimate Name is required!
            </Alert>
          )}
          <Row className="justify-content-end">
            <Col>
              <h1 className="text-start mb-4" id="pageTitleCal">
                Calculator
              </h1>
            </Col>
            {user && (
              <Col>
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={isFavorite ? solidStar : regularStar}
                    style={{
                      color: isFavorite ? "gold" : "transparent",
                      stroke: isFavorite ? "" : "gold",
                      strokeWidth: isFavorite ? "0" : "20",
                      cursor: "pointer",
                    }}
                    onClick={handleFavoriteToggle}
                    className="fw-bolder align-self-center my-4"
                  />
                </OverlayTrigger>
              </Col>
            )}
          </Row>

          {activeTab === "recent" ? (
            ""
          ) : (
            <Container>
              <div
                className="scroll-cal"
                id="scroll-tab"
                style={{
                  top: "126px",
                }}
              >
                <Scrollspy
                  items={["new", "bidAmount", "costProfit", "recent"]}
                  currentClassName="scroll-section is-current"
                  data-spy="scroll"
                  offset={3}
                >
                  <Nav variant="tabs" defaultActiveKey="#">
                    <Nav.Item>
                      <Nav.Link className="nav-link" href="#bidAmount">
                        Bid Amount
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="nav-link" href="#costProfit">
                        Cost & Profit
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Scrollspy>
              </div>
            </Container>
          )}
          <StyledTabs
            // defaultActiveKey={`${showTab}`}
            activeKey={activeTab}
            onSelect={showFormTab}
            transition={true}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="new" title="+"></Tab>
            <Tab
              style={{
                overflowY: "scroll",
                height: "80vh",
                overflowX: "hidden",
              }}
              eventKey="bidAmount"
              title={estimateTabName || "New Estimate"}
            >
              <StyledRow id="bidAmount">
                <Col
                  xs={12}
                  md={12}
                  xl={6}
                  // className="min-vh-100 mx-lg-5 ps-lg-5"
                  className="mx-lg-5 ps-lg-5"
                >
                  <h3 className="text-start mb-4 calculator-title">
                    Bid Amount
                  </h3>
                  <section
                    className="scroll-section is-current"
                    ref={bidAmountRef}
                  >
                    <CalculatorBidAmount
                      handlePricePerDay={handlePricePerDay}
                      handleSetLoginModal={handleSetLoginModal}
                      handleSetPaymentModalShow={handleSetPaymentModalShow}
                      bidAmount={bidAmountState}
                      handleBidAmountChange={calculateBidAmount}
                      setFormTouched={setFormTouched}
                    />
                  </section>
                </Col>

                <StyledSectionTwo className="col-xs-6 col-md-12  col-xl-5 ">
                  <Row className="space-between">
                    <Col>
                      {activeTab === "costProfit" ? (
                        <StyledPreviewText className="text-end">
                          Forecast prediction
                        </StyledPreviewText>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="space-between mb-5">
                    <CalculatorBidAmountResult
                      handleEditPricePerDay={handlePricePerDay}
                      bidAmount={bidAmountState}
                      handleIncreaseBidAmount={handleIncreaseBidAmount}
                      handleDecreaseBidAmount={handleDecreaseBidAmount}
                      handleBidAmountChange={manualBidAmount}
                      arrowAmount={arrowAmount}
                      bidAmountHandler={bidAmountHandler}
                      isEdit={isEdit}
                      handleSquareFootPriceChange={handleSquareFootPriceChange}
                    />
                  </Row>
                </StyledSectionTwo>
              </StyledRow>
              <hr />
              <Accordion
                activeKey={!selectedKey ? "0" : null}
                onSelect={handleAccordionSelect}
                style={{ marginLeft: "15px", marginBottom: "15px" }}
              >
                <Accordion.Item className="bg-white border-0" eventKey="0">
                  <StyledAccordionHeader className="fs-1 col-md-3">
                    {selectedName}
                  </StyledAccordionHeader>
                  <Accordion.Body>
                    <StyledRow id="costProfit">
                      <Col
                        xs={12}
                        md={12}
                        xl={6}
                        // className="min-vh-100 mx-lg-5 ps-lg-5"
                        className="mx-lg-5 ps-lg-5"
                      >
                        <h3 className="text-start mb-4 calculator-title">
                          Cost & Profit
                        </h3>
                        <section className="scroll-section">
                          <CalculatorCostProfit bidAmount={bidAmountState} />
                        </section>
                      </Col>
                      <StyledSectionTwo className="col-xs-6 col-md-12  col-xl-5">
                        <Row className="space-between mb-5">
                          <Col>
                            <StyledPreviewText className="text-start">
                              {values?.project_name
                                ? values?.project_name
                                : "Result"}
                            </StyledPreviewText>
                          </Col>
                          <Col>
                            <StyledPreviewText className="text-end">
                              Forecast prediction
                            </StyledPreviewText>
                          </Col>
                        </Row>
                        <Row className="space-between mb-5">
                          {bidAmountLaborLoader ||
                          stateLaborLoader ||
                          estimateLoader ? (
                            ""
                          ) : (
                            <CalculatorCostProfitResult
                              bidAmount={bidAmountState}
                              isEdit={isEdit}
                            />
                          )}
                        </Row>
                      </StyledSectionTwo>
                    </StyledRow>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Tab>

            {/* <Tab eventKey="costProfit" title="Cost & Profit"></Tab> */}
            <Tab eventKey="recent" title="Saved Estimates">
              <RecentEstimates
                setModalShow={setModalShow}
                modalShow={modalShow}
              />
            </Tab>
            <Tab eventKey="faq" title="FAQs"></Tab>
          </StyledTabs>
          {activeTab !== "recent" && !estimateListLoader && !estimateLoader && (
            <Row className="mb-3">
              <Col sm={5} className="text-center d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={submitAndCreate(false, handleButton)}
                  disabled={loading}
                >
                  <h4 className="p-2">
                    {" "}
                    {loading ? "Processing..." : buttonText}
                  </h4>
                </Button>
              </Col>
              <Col className="text-center d-flex">
                <Button
                  variant="success"
                  type="submit"
                  onClick={submitAndCreate(true, handleButton)}
                  disabled={loading}
                >
                  <h4 className="p-2">
                    {" "}
                    {loading
                      ? "Processing..."
                      : buttonText + " & Create Proposal"}
                  </h4>
                </Button>
              </Col>
            </Row>
          )}
        </StyledRow>
      </Container>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
    </div>
  );
};

export default CleanupCalculator;
