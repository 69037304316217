import React from "react";
import { Button, Card } from "react-bootstrap"; // Import React Bootstrap components
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ToolTip from "../tooltip/ToolTip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({
  tableData,
  dashboard,
  title,
  hidden,
  id,
  edit,
  handleToggleVisibility,
  infoHelper,
  todaySelected,
}) => {
  const maxValue = Math.max(...tableData.map((row) => row.count));
  const yPadding = (maxValue * 10) / 100; // 10% of the max value as padding
  const yMax = maxValue + yPadding;

  const data = {
    labels: tableData.map((row) => row.day),
    datasets: [
      {
        label: title,
        data: tableData.map((row) => row.count),
        borderColor: "rgba(75,192,192,1)",
        tension: 0.5,
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      title: {
        display: false, // Hide title
        text: "Sales Over Time",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const dateLabel = tooltipItems[0].label;
            const date = new Date(dateLabel);
            if (todaySelected) {
              return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            }
            return date.toLocaleDateString([], { month: "short", day: "numeric", year: "numeric" });
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          callback: function (value, index) {
            const dateLabel = this.getLabelForValue(value);
            const date = new Date(dateLabel);

            if (todaySelected) {
              return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            }
            return date.toLocaleDateString([], { month: "short", day: "numeric" });
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
        beginAtZero: false,
        max: yMax,
      },
    },
  };

  return (
    <Card className="h-100">
      <Card.Header
        className="d-flex justify-content-between align-items-center bg-transparent fs-4 fw-bold"
        style={{ border: "none" }}
      >
        <div className="d-flex flex-column">
          <span>
            {title} <ToolTip information={infoHelper} direction="bottom" />
          </span>
          <span>
            {tableData.reduce((total, item) => total + item.count, 0)}
          </span>
        </div>
        {edit && (
          <Button
            variant={hidden ? "outline-success" : "outline-danger"}
            onClick={() => handleToggleVisibility(id)}
            className="mt-3"
          >
            {hidden ? "Show Graph" : "Hide Graph"}
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <div
          style={{
            position: "relative",
            height: "max-content",
            minHeight: "200px",
          }}
        >
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChart;
