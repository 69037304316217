import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "../../components/dashboard/DatePicker";
import EditDashboard from "../../components/dashboard/EditDashboard";
import {
  getDashboard,
  saveDashboard,
} from "../../store/actions/dashboard/dashboard.action";
import LineChart from "../../components/dashboard/LineChart";
import Spinner from "../../components/spinner/Spinner";
import useIsMobile, { useIsPhone } from "../../utils/helpers/use-is-mobile";
import Details from "../../components/dashboard/Details";

const Dashboard = () => {
  const [edit, setEdit] = useState(false);
  const toLocalISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [startDate, setStartDate] = useState(
    toLocalISOString(new Date())
  );
  const [endDate, setEndDate] = useState(
    toLocalISOString(new Date())
  );
  const { user } = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.userDetails);
  const { dashboards, data, info, loading, error } = useSelector(
    (state) => state.dashboards
  );
  const graphs = dashboards
    ?.filter((g) => !g?.hidden)
    ?.sort((a, b) => a.index - b.index);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isPhone = useIsPhone();

  const handleDateChange = (start, end) => {
    setStartDate(start ? toLocalISOString(start) : start);
    setEndDate(end ? toLocalISOString(end) : end);
  };

  const handleEditChange = (e) => {
    setEdit(!edit);
    if (edit) dispatch(saveDashboard(dashboards));
  };

  useEffect(() => {
    if (user?.id) dispatch(getDashboard(startDate, endDate, user.id));
  }, [startDate, endDate, user?.id]);

  console.log("<<<<<< Data >>>>>>> ", data);
  return (
    <Container>
      <Row className="mt-5 text-start">
        <h1>You overview</h1>
        {user != null && (
          <DatePicker
            onChange={handleDateChange}
            onEdit={handleEditChange}
            edit={edit}
          />
        )}

        {loading ? (
          <div className="spinner-overlay">
            <div className="spinner-container">
              <Spinner />
            </div>
          </div>
        ) : user == null ? (
          <>
            <h3 className="pt-4 text-danger">Login to view the dashboard!</h3>
          </>
        ) : edit ? (
          <EditDashboard todaySelected={startDate === endDate} />
        ) : (
          <>
            <Row className="mt-5">
              {graphs?.slice(0, 3)?.map((graph, index) => (
                <Col
                  key={index}
                  sm={12}
                  lg={4}
                  style={{ margin: 0, padding: 0, ...(graph.hidden ? { border: '1px solid lightgray' } : {}) }}
                >
                  <div
                    className={`dashboard-card ${graph.hidden ? "hidden" : ""}`}
                  >
                    {graph.prop === "deals" ? (
                      <Details
                        id={graph.id}
                        title={graph.title}
                        edit={false}
                        hidden={graph.hidden}
                        data={data[graph.prop] || []}
                        dashboard={dashboards.find(dashboard => dashboard.prop === graph.prop)}
                        infoHelper={info[graph.prop]}
                        todaySelected={startDate && endDate && startDate === endDate}
                      />
                    ) : (
                      <LineChart
                        id={graph.id}
                        title={graph.title}
                        edit={false}
                        hidden={graph.hidden}
                        tableData={data[graph.prop] || []}
                        infoHelper={info[graph.prop]}
                        todaySelected={startDate && endDate && startDate === endDate}
                      />
                    )
                    }
                  </div>
                </Col>
              ))}
              {graphs?.slice(3, 6)?.map((graph, index) => (
                <Col
                  key={index}
                  sm={12}
                  lg={4}
                  style={{ margin: 0, marginBottom: '10px', padding: 0, ...(graph.hidden ? { border: '1px solid lightgray' } : {}) }}
                >
                  <div
                    className={`dashboard-card ${graph.hidden ? "hidden" : ""}`}
                  >
                    {graph.prop === "deals" ? (
                      <Details
                        id={graph.id}
                        title={graph.title}
                        edit={false}
                        hidden={graph.hidden}
                        data={data[graph.prop] || []}
                        infoHelper={info[graph.prop]}
                        todaySelected={startDate && endDate && startDate === endDate}
                      />
                    ) : (
                    <LineChart
                      id={graph.id}
                      title={graph.title}
                      edit={false}
                      hidden={graph.hidden}
                      tableData={data[graph.prop] || []}
                      dashboard={dashboards.find(dashboard => dashboard.prop === graph.prop)}
                      infoHelper={info[graph.prop]}
                      todaySelected={startDate && endDate && startDate === endDate}
                    />
                    )}
                  </div>
                </Col>
              ))}
              {!isMobile && graphs.length > 3 && Array.from({ length: 6 - (graphs?.length || 0) }).map(
                (_, index) => (
                  <Card
                    as={Col}
                    key={`placeholder-${index}`}
                    sm={6}
                    lg={4}
                    style={{ margin: 0, marginBottom: '10px', padding: 0, minHeight: '200px' }}
                  />
                )
              )}
            </Row>
          </>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
