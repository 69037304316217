import React from "react";
import { Button, Card } from "react-bootstrap"; // Import React Bootstrap components
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ToolTip from "../tooltip/ToolTip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Details = ({
  data,
  title,
  hidden,
  id,
  edit,
  handleToggleVisibility,
  infoHelper,
}) => {
  return (
    <Card className="h-100">
      <Card.Header
        className="d-flex justify-content-between align-items-center bg-transparent fs-4 fw-bold"
        style={{ border: "none" }}
      >
        <div className="d-flex flex-column">
          <span>
            {title} <ToolTip information={infoHelper} direction="bottom" />
          </span>
        </div>
        {edit && (
          <Button
            variant={hidden ? "outline-success" : "outline-danger"}
            onClick={() => handleToggleVisibility(id)}
            className="mt-3"
          >
            {hidden ? "Show Detail" : "Hide Detail"}
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <div
          style={{
            position: "relative",
            height: "max-content",
            minHeight: edit ? "185px" : "192px",
          }}
        >
          <div className="d-flex gap-3 mt-5">
            <h2 className="d-flex gap-5 flex-column fw-normal">
              <span>Total</span>
              <span>Deals</span>
              <span>Average</span>
            </h2>
            <h2 className="d-flex gap-5 flex-column fw-normal">
              <span>${new Intl.NumberFormat().format(data[0]?.total_deal_value || 0)}</span>
              <span>{new Intl.NumberFormat().format(data[0]?.count || 0)}</span>
              <span>${new Intl.NumberFormat().format(data[0]?.avg_deal_value || 0)}</span>
            </h2>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Details;
