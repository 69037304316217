import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export const DatePicker = ({ value, onChange, onEdit, edit }) => {
  const formatDate = (date) => {
    if (!date) return "";
    const options = { month: "short", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const [selectedValue, setSelectedValue] = useState(value || "today");
  const [dateRange, setDateRange] = useState("");

  const getDateRange = (selection) => {
    const today = new Date();
    let startDate, endDate;

    switch (selection) {
      case "today":
        startDate = endDate = today;
        break;
      case "last_7_days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        endDate = today;
        break;
      case "last_4_weeks":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 28);
        endDate = today;
        break;
      case "last_3_months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 3);
        endDate = today;
        break;
      case "last_12_months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 12);
        startDate.setDate(today.getDate() + 1);
        endDate = today;
        break;
      case "month_to_date":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = today;
        break;
      case "quarter_to_date":
        const currentQuarter = Math.floor(today.getMonth() / 3);
        startDate = new Date(today.getFullYear(), currentQuarter * 3, 1);
        endDate = today;
        break;
      case "year_to_date":
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = today;
        break;
      case "all":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = endDate = today;
    }

    if (selection === "today") {
      setDateRange(formatDate(startDate));
    } else if (selection === "all") {
      setDateRange(`All till ${formatDate(new Date())}`);
    } else {
      setDateRange(`${formatDate(startDate)} - ${formatDate(endDate)}`);
    }

    return { startDate, endDate };
  };

  const handleChange = (e) => {
    const selectedRange = e.target.value;
    setSelectedValue(selectedRange);
    const { startDate, endDate } = getDateRange(selectedRange);
    localStorage.setItem("selectedDateRange", selectedRange);
    onChange(startDate, endDate);
  };

  useEffect(() => {
    const savedRange = localStorage.getItem("selectedDateRange") || "today";
    setSelectedValue(savedRange);
    const { startDate, endDate } = getDateRange(savedRange);
    onChange(startDate, endDate);
  }, []); // Load from localStorage on component mount

  return (
    <Row className="mt-5">
      <div className="d-flex">
        <div className="d-flex align-items-center flex-grow-1">
          <Form.Select
            style={{ height: "40px", maxWidth: "max-content" }}
            value={selectedValue}
            onChange={handleChange}
          >
            <option value="today">Today</option>
            <option value="last_7_days">Last 7 days</option>
            <option value="last_4_weeks">Last 4 weeks</option>
            <option value="last_3_months">Last 3 months</option>
            <option value="last_12_months">Last 12 months</option>
            <option value="month_to_date">Month to date</option>
            <option value="quarter_to_date">Quarter to date</option>
            <option value="year_to_date">Year to date</option>
            <option value="all">All</option>
          </Form.Select>
          <div
            className="border border-gray align-content-center"
            style={{ height: "100%", paddingInline: "15px" }}
          >
            {dateRange}
          </div>
        </div>
        <Button
          variant={edit ? "primary" : "outline-secondary"}
          className="px-3 fs-5"
          onClick={onEdit}
          style={{ height: "max-content" }}
        >
          {edit ? "Done" : "Edit"}
        </Button>
      </div>
    </Row>
  );
};
