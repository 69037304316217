import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import LineChart from "./LineChart";
import { Row, Col, Button, Card } from "react-bootstrap";
import {
  REORDER_DASHBOARDS,
  TOGGLE_GRAPH_VISIBILITY,
} from "../../store/constants/dashboardConstants";
import "./editCard.css";
import useIsMobile, { useIsPhone } from "../../utils/helpers/use-is-mobile";
import Details from "./Details";

const toggleGraphVisibility = (id) => ({
  type: TOGGLE_GRAPH_VISIBILITY,
  payload: id,
});

const EditDashboard = ({ todaySelected }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isPhone = useIsPhone();

  const dashboards = useSelector(
    (state) => state.dashboards.dashboards.sort((a, b) => a.index - b.index) // Sort by index
  );
  const data = useSelector((state) => state.dashboards?.data);

  const onDragEnd = (result) => {
    let { source, destination } = result;

    if (!destination) return;

    source["droppableId"] = "dashboards";
    destination["droppableId"] = "dashboards";
    dispatch({
      type: REORDER_DASHBOARDS,
      payload: { source, destination },
    });
  };

  const handleToggleVisibility = (id) => {
    dispatch(toggleGraphVisibility(id));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="dashboards"
        direction={isMobile ? "vertical" : "horizontal"}
      >
        {(provided) => (
          <Row
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="gx-3 gy-4"
          >
            {dashboards.slice(0, 3).map((dashboard, index) => (
              <Draggable
                key={dashboard.id}
                draggableId={dashboard.id.toString()}
                index={index}
              >
                {(provided) => (
                  <Col
                    className={`dashboard-card ${
                      dashboard.hidden ? "greyed-out" : ""
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sm={12}
                    lg={4}
                  >
                    {dashboard.prop === "deals" ? (
                      <Details
                        id={dashboard.id}
                        title={dashboard.title}
                        edit={true}
                        hidden={dashboard.hidden}
                        data={data[dashboard.prop] || []}
                        dashboard={dashboards.find(
                          (dash) => dash.prop === dashboard.prop
                        )}
                        handleToggleVisibility={handleToggleVisibility}
                        todaySelected={todaySelected}
                      />
                    ) : (
                      <LineChart
                        id={dashboard.id}
                        title={dashboard.title}
                        edit={true}
                        hidden={dashboard.hidden}
                        tableData={data[dashboard.prop] || []}
                        handleToggleVisibility={handleToggleVisibility}
                        todaySelected={todaySelected}
                      />
                    )}
                  </Col>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Row>
        )}
      </Droppable>
      <Droppable
        droppableId="dashboards-2"
        direction={isMobile ? "vertical" : "horizontal"}
      >
        {(provided) => (
          <Row
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="gx-3 gy-4"
          >
            {dashboards.slice(3, 6).map((dashboard, index) => (
              <Draggable
                key={dashboard.id}
                draggableId={dashboard.id.toString()}
                index={index + 3}
              >
                {(provided) => (
                  <Col
                    className={`dashboard-card ${
                      dashboard.hidden ? "greyed-out" : ""
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sm={12}
                    lg={4}
                  >
                    {dashboard.prop === "deals" ? (
                      <Details
                        id={dashboard.id}
                        title={dashboard.title}
                        edit={true}
                        hidden={dashboard.hidden}
                        data={data[dashboard.prop] || []}
                        dashboard={dashboards.find(
                          (dash) => dash.prop === dashboard.prop
                        )}
                        handleToggleVisibility={handleToggleVisibility}
                        todaySelected={todaySelected}
                      />
                    ) : (
                      <LineChart
                        id={dashboard.id}
                        title={dashboard.title}
                        edit={true}
                        hidden={dashboard.hidden}
                        tableData={data[dashboard.prop] || []}
                        handleToggleVisibility={handleToggleVisibility}
                        todaySelected={todaySelected}
                      />
                    )}
                  </Col>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default EditDashboard;
