import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyleInput = styled(Form.Control)`
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 14px;
`;

export const StyledInput = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  style,
  disabled,
  loading,
  onBlur,
}) => {
  return (
    <>
      {loading ? (
        <h4 style={{ padding: "15px", fontSize: "14px" }}>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span> {"Loading... "}
        </h4>
      ) : (
        <StyleInput
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          style={style}
          disabled={disabled}
        />
      )}
    </>
  );
};
