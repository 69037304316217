import { toastError, toastSuccess } from "../../../utils/helpers/helper"
import { getDashboardAPI, saveDashboardAPI } from "../../../utils/requests/dashboard"
import { FAILURE_DASHBOARDS, FAILURE_SAVE_DASHBOARDS, REQUEST_DASHBOARDS, REQUEST_SAVE_DASHBOARDS, SUCCESS_DASHBOARDS, SUCCESS_SAVE_DASHBOARDS } from "../../constants/dashboardConstants"

export const getDashboard = (start_date, end_date, user) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_DASHBOARDS })
    getDashboardAPI(start_date, end_date, user)
      .then((response) => {
        dispatch({ type: SUCCESS_DASHBOARDS, response });
      })
      .catch((error) => {
        dispatch({ type: FAILURE_DASHBOARDS, error });
      });
  }
}

export const saveDashboard = (data) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_SAVE_DASHBOARDS })
    saveDashboardAPI(data)
      .then((response) => {
        dispatch({ type: SUCCESS_SAVE_DASHBOARDS, response });
        toastSuccess("Dashboard saved successfully");
      })
      .catch((error) => {
        toastError("Unable to save dashboard");
        dispatch({ type: FAILURE_SAVE_DASHBOARDS, error });
      });
  }
}
