import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import ProposalForm from "../../components/ProposalForm";
import RecentProposal from "../../components/RecentProposal";
import ProposalPreviewForm from "../../components/ProposalPreviewForm";
import LoginModal from "../../components/LoginModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadProposal,
  getProposalDetail,
  saveProposal,
  updateProposal,
} from "../../store/actions/proposals/proposals.actions";
import ProposalPreviewModal from "../../components/ProposalPreviewModal";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import SubscriptionModal from "../../components/subscriptionModal";
import { useNavigate } from "react-router";
import {
  PROPOSAL_RESSET_VALUES,
  PROPOSAL_SET_VALUES,
} from "../../store/constants/proposalConstants";
import { isSubscriptionActive, toastError } from "../../utils/helpers/helper";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import EmailModal from "../../components/emailModal";
import { updateContactLastDateByName, updateProjectContactLastDateByName } from "../../store/actions/projects/projects.action";

const StyledSectionTwo = styled.div`
  background-color: rgb(246 248 250);
`;
const StyledRow = styled(Row)`
  padding-top: 4rem;
`;

const StyledPreviewText = styled.h3`
  color: #000;
  font-weight: 700;
`;

const StyledTabs = styled(Tabs)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .nav-item button#noanim-tab-example-tab-new {
    color: #fff;
    background-color: #276fb4;
  }
`;

const DocumentGenerator = () => {
  const isMobile = useIsMobile();
  let params = useParams();
  const { search } = window.location;
  const show_tab = new URLSearchParams(search).get("show_tab");
  let [searchParams] = useSearchParams();
  const project_type = searchParams.get("project_type");
  const { id } = params;
  const [modalShow, setModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [proposalPreviewModal, setProposalPreviewModal] = useState(false);
  const [showTab, setShowTab] = useState("home");
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [proposalData, setProposalData] = useState();
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile, loading } = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const proposalList = useSelector((state) => state.proposalList);
  const { proposals } = proposalList;
  const { proposal } = useSelector((state) => state.proposalDetail);
  const { projectTypes } = useSelector((state) => state.projectTypeList);
  const { projectTypes: userProjectTypes } = useSelector((state) => state.userProjectTypeList);
  const values = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);
  const { error: errorForm, loading: createLoader } = useSelector(
    (state) => state.proposalCreate
  );
  const { loading: downloader } = useSelector(
    (state) => state.proposalDownload
  );
  const { hasFeatureAccess, enforceAccess } =
    useFeatureAccess("Cleanup Proposal");
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const price_id = process.env.REACT_APP_PROPOSAL_APP;

  let proposalId;
  let proposalObject;
  if (proposals.length > 0) {
    if (id) {
      proposalId = proposal?.id;
      proposalObject = proposal;
    }
  }

  const handleSetPaymentModalShow = (e) => {
    e.preventDefault();
    localStorage.setItem("proposal", JSON.stringify(values));
    setPaymentModalShow(true);
  };

  const showFormTab = (e) => {
    if (e === "home") {
      setShowTab("home");
    } else if (e === "recent") {
      setShowTab("recent");
    } else if (e === "new") {
      dispatch({ type: PROPOSAL_RESSET_VALUES });

      const select_project = projectTypes.find((projectType) => {
        return (
          projectType.name.toLowerCase() == values.project_type.toLowerCase()
        );
      });

      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: {
          user_template:
            select_project?.user_template || select_project?.template,
        },
      });

      navigate("/my_proposal");
    } else if (id !== undefined) {
      setShowTab("home");
    }
  };
  const handleDownload = (e, id, proposal) => {
    let newValues = values;
    let template = values?.user_template;

    let trueKeys = Object.keys(sections)
      .map((key) => {
        if (sections[key]) {
          return key;
        }
      })
      .filter((key) => key);

    if (user) {
      newValues = {
        ...values,
        ...profile,
        current_date: new Date().toUTCString().slice(0, 16),
      };
    }

    try {
      let select_project = projectTypes.find((projectType) => {
        return (
          projectType.name.toLowerCase() ==
          (values?.project_type?.toLowerCase() || project_type.toLowerCase())
        );
      });

      if (!select_project) {
        select_project = projectTypes.find((projectType) => {
          return projectType.name == "Any Type General Cleaning";
        });
      }

      newValues = {
        ...newValues,
        project_type: select_project.name,
        user_template:
          template != null
            ? template
            : select_project?.user_template || select_project?.template,
        sections: trueKeys.join(","),
      };

      if (!user.id) {
        localStorage.setItem("proposal", JSON.stringify(values));
        setModalShow(true);
      } else {
        if (!hasFeatureAccess) {
          enforceAccess();
        } else if (id) {
          dispatch(updateProposal(values?.id, newValues, true));
        } else {
          dispatch(saveProposal(newValues, true));
        }
      }
    } catch (e) {
      toastError("Unable to download proposal");
    }
  };

  const path = global.window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = id ? values?.project_name : "Proposal Generator";
    const type = "Proposal Generator";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, values?.project_name]);

  useEffect(() => {
    if (id && user) {
      dispatch(getProposalDetail(id));
      // setShowTab("home");
    }
    if (show_tab) {
      setShowTab("recent");
    }
  }, [dispatch, id, show_tab, user]);

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Proposal Generator" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({ page_name: "Proposal Generator", page_url: path })
      );
    }
  };

  const convertHtml = (html) => {
    return html
      ?.replaceAll("&nbsp;", " ")
      ?.replaceAll("<div><div>", "\n")
      ?.replaceAll("<div>", "")
      ?.replaceAll("</div><br>", "\n")
      ?.replaceAll("</div>", "\n")
      ?.replaceAll("<br>", "\n")
      ?.replaceAll("<br/>", "\n")
      ?.replaceAll("<br />", "\n")
      ?.replaceAll("&#39;", "'")
      ?.replaceAll("<p>", "")
      ?.replaceAll("</p>", "");
  };

  const handleSendEmail = (appName, email_body) => {
    const data = proposalData || values;
    const recipient = [
      data?.project_contact_1_email,
      data?.project_contact_2_email,
      data?.project_contact_3_email,
      data?.project_contact_4_email,
    ].filter((email) => email);

    const recipientNames = [
      data?.project_contact_1_name,
      data?.project_contact_2_name,
      data?.project_contact_3_name,
      data?.project_contact_4_name,
    ]
    .filter((name) => name)
    .map(fullName => {
      const names = fullName.split(' ');
      return names[0];
    });

    let greeting = 'Hi';
    if (recipientNames.length === 1) {
      greeting += ` ${recipientNames[0]},`;
    } else if (recipientNames.length === 2) {
      greeting += ` ${recipientNames[0]} and ${recipientNames[1]},`;
    } else if (recipientNames.length > 2) {
      const last = recipientNames.pop();
      greeting += ` ${recipientNames.join(', ')}, and ${last},`;
    }

    const message = convertHtml(email_body);
    const subject = `${user?.company_name?.toUpperCase()} - Cleaning Proposal for ${data?.project_name || ''} $${Number(data?.bid_amount)}`;
    const body = `${greeting}\n\n${message}\n\n${profile?.proposal_point_contact_name}\n${profile?.proposal_point_contact_phone}\n${profile?.proposal_point_contact_email}\n\n${profile?.company_name && profile?.company_name + '\n'}${profile?.company_street && profile?.company_street + '\n' }${profile?.company_city ? profile?.company_city + ', ' : ''}${profile?.company_state} ${profile?.company_zip}`;
    const simple_body = `${greeting}\n\n${message}`;

    // Mailto link for default mail client
    const defaultMailLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Gmail link
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Outlook link
    const outlookLink = `https://outlook.office.com/owa/?path=/mail/action/compose&to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    // Yahoo Mail link
    const yahooLink = `https://compose.mail.yahoo.com/?to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    switch (appName) {
      case 'gmail':
        window.open(gmailLink, '_blank');
        break;
      case 'yahoo':
        window.open(yahooLink, '_blank');
        break;
      case 'outlook':
        window.open(outlookLink, '_blank');
        break;
      case 'default':
        window.open(defaultMailLink, '_blank');
        break;
    }

    const today = new Date().toISOString();
    for(let rec in recipient) {
      dispatch(updateContactLastDateByName(today.slice(0, 16), recipient[rec], email_body));
    }
    dispatch(updateProjectContactLastDateByName(today.slice(0, 16), data?.project_name))

    proposalData?.id ?
      dispatch(downloadProposal(proposalData.id, proposalData)):
      dispatch(handleDownload(null, proposalId, values));
  }

  useEffect(() => {
    dispatch(fetchPageFavorite("Proposal Generator"));
  }, [dispatch]);
  return (
    <div className="bg-white ">
      <Container>
        {user && (
          <Row className="justify-content-end">
            <Col md={6}>
              <FontAwesomeIcon
                size={"2x"}
                icon={isFavorite ? solidStar : regularStar}
                style={{
                  color: isFavorite ? "gold" : "transparent",
                  stroke: isFavorite ? "" : "gold",
                  strokeWidth: isFavorite ? "0" : "20",
                  cursor: "pointer",
                }}
                onClick={handleFavoriteToggle}
                className="fw-bolder align-self-center my-4"
              />
            </Col>
          </Row>
        )}
        <StyledRow>
          <Col xs={12} md={12} className="my-proposal-col">
            <StyledTabs
              activeKey={showTab}
              onSelect={showFormTab}
              transition={true}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="new"
                title="+"
                style={{ background: "blue", color: "white" }}
              />
              <Tab
                eventKey="home"
                title={
                  values?.project_name
                    ? values.project_name
                    : "Create New Proposal"
                }
                className="text-uppercase"
              >
                <Row>
                  <StyledSectionTwo className="col-sm-8 py-4 px-4">
                    <StyledPreviewText className="text-start">
                      Preview Section
                    </StyledPreviewText>
                    <div style={{ textTransform: "none" }}>
                      <ProposalPreviewForm />
                      <div className="text-center row mb-5 m-4 justify-content-center">
                        {isMobile ? (
                          <>
                            <Button
                              onClick={() => setProposalPreviewModal(true)}
                              className="me-5 col-md-3 "
                              disabled={downloader}
                            >
                              <h4>Preview</h4>
                            </Button>
                          </>
                        ) : (
                          <>
                            <div className="text-center"></div>
                            <Button
                              onClick={() => setProposalPreviewModal(true)}
                              className="me-5  col-md-2"
                            >
                              <h4> Preview</h4>
                            </Button>
                          </>
                        )}
                        {user &&
                          proposals.length > 0 &&
                          (isMobile ? (
                            <>
                              <Button
                                className="btn-success"
                                style={{ color: "#fff" }}
                                onClick={(e) =>
                                  handleDownload(e, proposalId, proposalObject)
                                }
                                disabled={downloader || createLoader}
                              >
                                <h4>
                                  Download{" "}
                                  {downloader ||
                                    (createLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ))}
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>{" "}
                                </h4>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="btn-success  col-md-2 "
                                style={{ color: "#fff" }}
                                onClick={(e) =>
                                  handleDownload(e, proposalId, proposalObject)
                                }
                                disabled={downloader || createLoader}
                              >
                                <h4>
                                  Download{" "}
                                  {downloader ||
                                    (createLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ))}
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>{" "}
                                </h4>
                              </Button>
                            </>
                          ))}
                        {user?.company_name &&
                          <Button
                            className="btn-secondary ms-5 col-md-2"
                            style={{ color: "#fff" }}
                            onClick={(e) => {
                              if (!user?.id) {
                                localStorage.setItem("proposal", JSON.stringify(values));
                                setModalShow(true);
                              } else {
                                setEmailModalShow(true);
                                setProposalData(null);
                              }
                            }}
                          >
                            <h4>Email Proposal</h4>
                          </Button>
                        }
                      </div>
                    </div>
                  </StyledSectionTwo>
                  <div className="col-sm-4">
                    <ProposalForm
                      setModalShow={setModalShow}
                      setPaymentModalShow={setPaymentModalShow}
                      setShowTab={setShowTab}
                    />
                  </div>
                </Row>
              </Tab>
              <Tab eventKey="recent" title="Saved Proposals">
                <RecentProposal
                  setModalShow={setModalShow}
                  showFormTab={showFormTab}
                  showEmailPopup={(proposal) => {
                    setEmailModalShow(true);
                    setProposalData(proposal);
                  }}
                />
              </Tab>
            </StyledTabs>
          </Col>
        </StyledRow>
      </Container>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
      <ProposalPreviewModal
        show={proposalPreviewModal}
        onHide={setProposalPreviewModal}
      />
      <EmailModal
        projectName={proposalData?.project_name || values?.project_name || ""}
        show={emailModalShow}
        onHide={() => {
          setEmailModalShow(false)
          setProposalData(null)
        }}
        onChange={handleSendEmail}
      />
    </div>
  );
};

export default DocumentGenerator;
